@import "../../../../css/fonts";

.dashboardCredits {
    cursor: pointer;

    .body {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .more {
            @include font-rubik(32px, var(--midnight-100), 400);
            cursor: pointer;
            transition: color 0.25s ease-out;

            &:hover {
                @include font-rubik(32px, var(--gray-neutral), 400);
            }

            &--disabled {
                @include font-rubik(32px, var(--gray-neutral), 400);
                cursor: default;
            }

            .icon {
                font-size: 32px;
            }
        }

        .remaining {
            display: flex;
            align-items: center;

            .number {
                @include font-rubik(48px, var(--blue-darkerer), 500);
                display: flex;
                align-items: center;
                height: 72px;
                margin-right: 8px;

                .loading {
                    width: 30px;
                    height: 42px;
                    border-radius: 4px;
                    transform: translateY(-1px);
                }
            }

            .text {
                @include font-rubik(16px, var(--midnight-100), 300);
                display: flex;
                flex-direction: column;
            }
        }
    }
}
