@import "fonts";
@import "variables";

/* Cards */

.card-w-image {
    &-container {
        width: 100%;
        margin-bottom: 24px;
        cursor: pointer;
    }

    &-image {
        width: 100%;
        height: 250px;
        background-position: center;
        background-size: cover;
    }

    &-text {
        width: 100%;
        padding: 24px;
        background-color: var(--white);
    }
}

/* Dividers */

hr {
    background-color: var(--gray-darkerer);
}

/* Flex */

.flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flex-end {
    justify-content: flex-end !important;
}

.flex-row-center {
    display: flex;
    justify-content: center;
}

.flex-row-space-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Form & Inputs */

.form {
    position: relative;
    margin-bottom: 24px;

    &-header {
        @include font-rubik(16px, var(--gray-darkest), bold);
        display: block;
        margin-bottom: 8px;
    }

    .disabled-checkmark {
        position: relative;

        &:before {
            position: absolute;
            top: 22px;
            left: calc(100% - 50px);
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #669b7c;
            font-family: "Material Icons", sans-serif;
            line-height: 24px;
            text-align: center;
            color: white;
            transition: all 0.5s ease-out;
            content: "\E5CA";
        }
    }
}

/* Highlight block */

.highlight-block {
    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include phone {
            margin-bottom: 24px;
        }
    }

    &-image {
        width: 72px;
        height: auto;
        margin-bottom: 15px;
    }

    &-header {
        @include font-rubik(24px, var(--midnight-100));
    }

    &-text {
        padding: 0 24px;
        font-size: 18px;
        text-align: center;
        color: var(--gray-darkerer);
    }
}

/* Links */

.link {
    @include font-rubik(false, var(--pacific-blue-100), 300);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    &-disabled,
    &--disabled {
        color: var(--gray-dark);
        cursor: not-allowed;
    }

    &-negative {
        color: var(--error-100);
    }
}

/* Lists */

.list-w-leads {
    padding-left: 0;
    list-style-type: none;

    &-item {
        &-lead {
            @include font-rubik(16px, var(--gray-darkest), bold);
        }
    }
}

/* Modal */
/* Remove "new-" from class names once we phase out the old modal styling */
/* Nav (Bootstrap Override) */

#basic-nav-dropdown {
    display: flex;
    align-items: center;
    padding-left: 0;
}

.basic-nav-dropdown {
    &-container {
        display: flex;
        align-items: center;
        margin-right: 5px;
    }

    &-avatar {
        width: 30px !important;
        height: 30px !important;
        margin-right: 10px;
        border-radius: 50% !important;
        background-size: cover !important;
    }
}

/* Places Auto Complete */

.places-auto-complete {
    &-item {
        display: flex;
        align-items: center;
        font-size: 18px;

        &-city {
            @include font-rubik(16px, var(--midnight-100), 400);
            transform: translateY(2px);
        }

        &-marker {
            margin-right: 5px;
        }
    }
}

/* React Intl Tel Input */

.intl-tel-input {
    width: 100%;
}

/* Section */

.section {
    &-container {
        width: 100%;
        padding: 24px 48px;

        @include phone {
            padding: 24px;
        }
    }

    &-header {
        @include font-rubik(24px, var(--midnight-100), bold);
        display: inline-block;
        width: 100%;
        margin-bottom: 24px;
        text-align: center;
    }
}

/* Text Highlights */

.orange-highlight {
    @include font-rubik(16px, var(--mission-orange-100), 700);
}

.red-highlight {
    @include font-rubik(false, var(--error-100), false);
}

.Select-control {
    height: 48px;
    border: none;
    width: 102%;
    border-radius: 0;
}

.Select-control:hover,
.Select-control:focus {
    box-shadow: none;
}

.Select-value {
    font-size: 18px;
}

.Select-placeholder,
:not(.Select--multi) > .Select-control .Select-value {
    top: 5px;
}

.Select-arrow-zone {
    top: 2px;
}

.has-value:not(.Select--multi)
    > .Select-control
    > .Select-value
    .Select-value-label {
    color: var(--midnight-100);
}

.left-search-selector {
    border: 1px solid rgba(var(--gray-darkerer), 0.5);
}

.has-value {
    border: none;
}

/*  Component load fade in */

.example-appear {
    opacity: 0.01;
}

.example-appear.example-appear-active {
    opacity: 1;
    transition: opacity 1s ease-in;
}
