@import "../../../css/fonts";
@import "../../../css/variables";

.dashboard__compose {
    position: relative;
    display: flex;
    flex-direction: column;
    border: none;
    overflow: hidden;

    & > *:not(:last-child) {
        margin-bottom: 16px;
    }

    .button {
        align-self: start;
    }

    .image {
        position: absolute;
        top: 0;
        left: 100%;
        max-width: 50%;
        transform: translate(-100%, 0);

        @include mobile {
            display: none;
        }
    }

    .text {
        @include font-rubik(16px, false, 400);
        display: flex;
        flex-direction: column;
    }
}
