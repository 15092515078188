@import "../../../css/fonts";
@import "../../../css/variables";

.dashboard__weekly-updates {
    display: flex;
    flex-direction: column;

    & > *:not(:last-child) {
        border-bottom: 1px solid var(--gray-light);
    }
}
